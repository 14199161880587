.create-event-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.create-event-container h2 {
    text-align: center;
    color: #0bd3d3;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="date"],
.form-group input[type="time"],
.form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
}

.form-group input[type="checkbox"],
.form-group input[type="radio"] {
    margin-right: 10px;
}

button[type="submit"] {
    background-color: #0bd3d3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    display: block;
    width: 100%;
}

button[type="submit"]:hover {
    background-color: #09b8b8;
}

/* Additional styles for modal, overlay, or other components if needed */
