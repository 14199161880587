/* NewLandingPage.css */

/* Reset some basic elements */
body, h1, h2, h3, h4, p, a {
    margin: 0;
    padding: 0;
}

/* Apply a basic color scheme */
body {
    font-family: 'Arial', sans-serif;
    color: #ffffff;
    background-color: #000000;
    line-height: 1.6;
    font-size: 18px;
}

/* Style the header */
.new-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5%;
    background-color: #1a1a1a;
}

.corner-image {
    max-width: 125px; /* Adjust the maximum width as needed */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* To prevent inline default space below the image */
    margin: 1rem 0; /* Optional: Adds space around the image */
align-self: center;}
.new-logo {
    font-size: 1.5rem;
    font-weight: bold;
    height: 50px;  
    margin-right: 15px;  
    vertical-align: middle;
}
.new-logo-main {
    font-size: 1.5rem;
    font-weight: bold;
    height: 100px;  
    margin-right: 15px;  
    vertical-align: middle;
}

.new-navigation a {
    color: #ffffff;
    text-decoration: none;
    margin-left: 2rem;
    font-size: 1rem;
}

.new-main-content {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Align vertically */
    justify-content: center; /* Align horizontally */
    padding: 5rem 0; /* Add some padding */
    text-align: center;
  }
  
  .new-split-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Use the full width */
  }
  
  .new-split-left, .new-split-right {
    width: 50%; /* Split the width */
    padding: 2rem; /* Add some padding */
  }
  .new-split-left  {
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
  }
  
  .new-main-logo {
    max-width: 100%; /* Ensure the logo is fully visible */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 2rem; /* Space between logo and text */
  }
  
  .new-main-image {
    width: 100%; /* Ensure the image takes up the right column width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove any extra space below the image */
  }
  
  /* Add styles for the heading and paragraph */
  .new-split-left h1 {
    font-size: 3rem; /* Large font size for the title */
    color: #fff; /* White text color */
    margin-bottom: 1rem; /* Space after the title */
  }
  
  .new-split-left p {
    font-size: 1.2rem; /* Size of the paragraph text */
    color: #aaa; /* Lighter text color for the paragraph */
  }

/* Style the footer */
.new-footer {
    background-color: #1a1a1a;
    color: #888;
    padding: 3rem 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-footer h4,
.new-footer p {
    margin-bottom: 1rem;
}

/* Center the legal links and separator */
.new-footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.new-footer-links a,
.link-separator {
    margin: 0 10px;
    color: #888;
    text-decoration: none;
}

.new-footer-links a:hover {
    color: #fff;
}

/* Center the legal links and separator */
.new-footer-category div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Wrap links if necessary */
}

.new-footer-category a,
.link-separator {
    margin: 0 10px; /* Space out the links and separator */
    color: #888;
    text-decoration: none;
}

.new-footer-category a:hover {
    color: #fff;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .new-header, .new-features, .new-footer-content {
        flex-direction: column;
    }

    .new-navigation a {
        margin: 0.5rem 0;
    }

    .new-feature-item {
        width: 100%;
        margin-bottom: 2rem;
    }

    .new-learn-more {
        width: 100%;
        padding: 1rem;
        font-size: 1.2rem;
    }

    .new-split-section {
        flex-direction: column-reverse; /* Stack the columns and reverse the order */
    }

    .new-split-left, .new-split-right {
        width: 100%; /* Each section takes full width on small screens */
    }

    .new-main-image {
        margin-top: 2rem; /* Add space between the text and image on small screens */
    }
    
    .new-split-right {
        order: 1; /* Will make split-right go above split-left */
    }
    /* If you want to explicitly set the order, you can do so with the order property */
    .new-split-left {
        order: 2; /* Will make split-left go below split-right */
    }
    
}
