.admin-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    min-height: 100vh; /* Full height */
    padding: 20px;
}

.admin-header {
    background-color: #0bd3d3;
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
}

.admin-nav {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.admin-link {
    background-color: #ffffff;
    color: #0bd3d3;
    text-decoration: none;
    padding: 10px 20px;
    border: 2px solid #0bd3d3;
    border-radius: 5px;
    transition: color 0.3s ease, background-color 0.3s ease;
    font-weight: 600;
    text-align: center;
    display: inline-block;
}

.admin-link:hover, .admin-link:focus {
    background-color: #0bd3d3;
    color: white;
    text-decoration: none;
}
.admin-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: 1px solid #CCCCCC; /* Light gray border */
    background-color: #F9F9F9; /* Light gray background */
    color: #333333; /* Dark text for contrast */
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  }
  
  .tab-button:hover {
    background-color: #E0E0E0; /* Slightly darker background on hover */
  }
  
  .tab-button.active {
    background-color: #0bd3d3; /* Primary color of your app */
    color: #FFFFFF; /* White text for better visibility */
    border-color: #0bd3d3; /* Border color same as background */
  }

/* Additional styling for nested content if needed */
/* Modal Styles */
.admin-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px; /* Adjust width as needed */
    max-width: 90%; /* Ensure it doesn't exceed the screen width */
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content input[type="text"],
  .modal-content input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .modal-content button:last-child {
    background-color: #6c757d; /* Different color for close button */
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  
  .modal-content .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  /* Existing styles */
.reported-posts-table, .reported-users-table, .detailed-reports-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .reported-posts-table th, 
  .reported-posts-table td, 
  .reported-users-table th, 
  .reported-users-table td,
  .detailed-reports-table th, 
  .detailed-reports-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .reported-posts-table th, 
  .reported-users-table th,
  .detailed-reports-table th {
    background-color: #f4f4f4;
  }
  
  /* New styles for detailed reports */
  .detailed-reports-table {
    background-color: #f0f0f0; /* Light gray background for detailed reports */
    margin-top: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
  }
  
  .detailed-reports-table tr:nth-child(even) {
    background-color: #e9e9e9; /* Slightly darker for even rows in detailed reports */
  }
  
  .detailed-reports-table tr:hover {
    background-color: #d9d9d9; /* Highlight on hover */
  }
  