.add-teams-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.add-teams-container h2 {
    text-align: center;
    color: #0bd3d3;
}

.team-form-group {
    margin-bottom: 15px;
}

.team-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.team-form-group input[type="text"],
.team-form-group input[type="file"],
.team-form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
}

.team-button[type="submit"] {
    background-color: #0bd3d3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    display: block;
    width: 100%;
}

.team-button[type="submit"]:hover {
    background-color: #09b8b8;
}

/* Additional styles for image preview */
.image-preview {
    width: 100px;
    height: 100px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    object-fit: cover;
}
