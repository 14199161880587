.events-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.events-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.events-table th, .events-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.events-table th {
    background-color: #0bd3d3;
    color: white;
}



button {
    background-color: #0bd3d3;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #09b8b8;
}
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    z-index: 1000;
    width: 80%;
    max-width: 500px; /* Adjust the width as necessary */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-inner {
    display: flex;
    flex-direction: column;
}

.popup-inner h3 {
    margin-bottom: 20px;
}

.popup-inner label {
    display: block;
    margin-bottom: 5px;
}

.popup-inner input[type="text"],
.popup-inner input[type="number"],
.popup-inner .react-datepicker-wrapper {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the buttons */
.popup-inner button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
}

/* Style for the Update button */
.popup-inner button[type="submit"] {
    background-color: #0bd3d3;
    color: white;
    margin-right: 10px;
}

/* Style for the Cancel button */
.popup-inner button:not([type="submit"]) {
    background-color: #f44336;
    color: white;
}

/* Adjust this if you want to increase the gap between the buttons */
.popup-inner button + button {
    margin-left: 10px;
}

/* Add media queries as needed for responsiveness */
@media (max-width: 600px) {
    .popup {
        width: 95%;
        max-width: none;
    }
}
