/* SponsorEvents.css */
.sponsor-events-container {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .page-title {
    color: #333;
    text-align: center;
  }
  
  .sponsor-selection {
    margin-bottom: 20px;
  }
  
  .dropdown-label {
    margin-right: 10px;
  }
  
  .dropdown {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .sponsor-details {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .details-title {
    margin-bottom: 15px;
  }
  
  .detail {
    margin-bottom: 5px;
  }


  .sponsor-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px; /* Adjust width as needed */
    max-width: 90%; /* Ensure it doesn't exceed the screen width */
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content input[type="text"],
  .modal-content input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .modal-content button:last-child {
    background-color: #6c757d; /* Different color for close button */
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  
  .modal-content .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* Adjustments to existing styles if needed */
  .events-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Add spacing above the table */
  }
  
  .events-table th, 
  .events-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .events-table th {
    background-color: #f4f4f4;
  }
  
  .events-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  