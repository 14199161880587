/* App.css */
.App {
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
}

/*header {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #0bd3d3;
}*/

button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #eee;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ddd;
}

button.active {
  background-color: #007bff;
  color: white;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #0bd3d3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.admin-link {
  font-weight: 600;
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid #333;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
      background-color: #333;
      color: #fff;
  }
}
/*controlling padding on elements + other general styling of sections*/
.content, footer, header {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
.content, footer {
  padding: 2em 10%;
}
.content-side {
  padding: 0 10%;
}
header {
  padding: 0 calc(10% - 3em);
}
section {
  position: relative;
}
section .desktop {
  display: none;
}
@media (min-width: 40rem){
  .content, footer {
    padding-left: 15%;
    padding-right: 15%;
  }
  .content-side {
    padding: 0 15%;
  }
  header {
    padding: 0 calc(15% - 3em);
  }
  section .mobile {
    display: none;
  }
  section .desktop {
    display: inline-block;
  }
}
@media (min-width: 60rem){
  .content, footer {
    padding-left: 20%;
    padding-right: 20%;
  }
  .content-side {
    padding: 0 20%;
  }
  header {
    padding: 0 calc(20% - 3em);
  }
}

/*styling divider icon element*/
.divider-img {
  display: block;
  width: 3.5em;
}

/*styling header*/
#header-logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;
  padding-right: 1em;
  color: var(--white);
  font-weight: bold;
  font-size: 1.25em;
}
#header-logo img {
  width: 3em;
  height: 3em;
}

/*styling landing element*/
@keyframes fadeSlide {
  from {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
#landing-back-img {
  min-width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
#landing-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(var(--black-rgb), 0.5), var(--black));
  z-index: 0;
}
#landing-wrapper {
  text-align: center;
}
#landing-wrapper > * {
  position: relative;
  z-index: 1;
}
#landing-wrapper h1 {
  margin: 0;
  margin-bottom: 0.25em;
  font-size: 3.5em;
  line-height: 1.25;
  opacity: 0;
  animation: fadeUp 0.75s ease forwards;
}
#landing-wrapper h1 span {
  display: block;
}
#landing-wrapper p {
  text-align: left;
  opacity: 0;
  animation: fadeUp 0.75s 0.3s ease forwards;
}
#landing-wrapper .download-button {
  opacity: 0;
  margin-top: 1em;
}
#landing-wrapper .download-button.mobile {
  margin-bottom: 2em;
  opacity: 0;
  animation: fadeUp 0.75s 0.15s ease forwards;
}
#landing-wrapper .img-wrapper {
  display: none;
}
/*allowing the title on the same line for ipad-esque widths*/
@media (min-width: 30rem){
  #landing-wrapper h1 span {
    display: inline;
  }
  #landing-wrapper h1 span::after {
    content: ". ";
  }
}

/*features section styling*/
#landing-section + .feature {
  margin-top: 0;
}
.feature-heading {
  font-size: 1.75em;
  font-weight: bold;
  color: var(--white);
  margin: 0.25em 0;
}
.feature-img {
  width: 100%;
}
.feature:nth-of-type(2n + 1) .divider-img {
  transform: scaleX(-100%);
}

/*cta styling*/
#cta {
  margin: 2em 0;
  text-align: center;
}
#cta .divider-img {
  position: absolute;
  top: 1em;
  left: 50%;
  transform: translate(-50%, -50%);
}
#cta > div.content-side {
  padding-top: 3em;
}
#cta p {
  font-size: 1.75em;
  font-weight: bold;
  color: var(--white);
  margin: 0 auto;
  max-width: 15em;
}
#cta .download-button {
  margin-top: 1.5em;
}
#cta #download-area {
  position: relative;
  padding-bottom: 5em;
}
#cta .cta-back-image {
  position: absolute;
  bottom: 0;
  height: 100%;
  max-width: 100%;
}
#cta .cta-back-image > * {
  width: 100%;
  height: 100%;
}
#cta .cta-back-image.cta-right {
  right: 0;
}
#cta .cta-back-image.cta-left {
  left: 0;
}

/*footer styling*/
footer .footer-heading {
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
}
footer .footer-heading + * {
  margin-top: 0.5em;
}
#footer-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5em 1em;
}
#footer-form input {
  max-width: 15em;
  flex-grow: 1;
}
#footer-form .styled-button {
  margin: 0;
}
#footer-links {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#footer-links > * {
  margin: 0.125em 0;
}
#footer-icon-links {
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  gap: 1em;
}
#footer-icon-links a {
  height: 1.5em;
  max-height: 32px;
}

/*side-by-side desktop styling*/
@media (min-width: 40rem){
  /*landing page*/
  #landing-wrapper {
    display: flex;
    align-items: stretch;
    text-align: left;
  }
  #landing-wrapper::before {
    opacity: 0.2;
  }
  #landing-wrapper > * {
    flex: 1 1 0;
  }
  #landing-wrapper #landing-text {
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 0 1em 1em rgba(var(--black-rgb), 0.7);
    background: rgba(var(--black-rgb), 0.7);
  }
  #landing-wrapper .img-wrapper {
    display: block;
    animation: fadeUp 0.75s ease forwards;
  }
  #landing-wrapper .download-button {
    animation: fadeSlide 0.75s 0.3s ease forwards;
  }
  #landing-wrapper h1 {
    animation: fadeSlide 0.75s ease forwards;
  }
  #landing-wrapper h1 span {
    display: block;
  }
  #landing-wrapper p {
    animation: fadeSlide 0.75s 0.15s ease forwards;
  }
  #landing-wrapper h1 span::after {
    content: "";
  }

  /*side-by-side flex styling for features, as well*/
  .feature {
    display: flex;
    align-items: center;
  }
  .feature > * {
    flex: 1 1 0;
    width: 0;
  }
  .feature > div:first-child,
  .feature:nth-of-type(2n + 1) div:nth-child(2) {
    margin-right: 10%;
  }
  .feature:nth-of-type(2n + 1) > div:first-child {
    margin-right: 0;
  }
  .feature:nth-of-type(2n + 1) {
    flex-direction: row-reverse;
  }

  /*cta with larger side graphics*/
  #cta > div.content-side {
    padding-top: 2em;
  }
  #cta #download-area {
    padding-bottom: 2em;
  }
  #cta .cta-back-image {
    max-height: 11em;
    top: 50%;
    transform: translateY(-50%);
  }

  /*footer*/
  footer {
    display: flex;
    align-items: flex-start;
  }
  #footer-text {
    position: relative;
    flex: 2.5 1 0;
    padding-right: 2em;
  }
  #footer-text::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: var(--text);
  }
  #footer-text div:first-child .footer-heading {
    margin-top: 0;
  }
  #footer-links {
    flex: 1 1 0;
    padding-left: 2em;
    margin-top: 0;
  }
  #footer-icon-links {
    margin-top: 2em;
  }
}